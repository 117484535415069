<template>
  <div class="page-layout">
    <div class="hero">
      <div class="hero-body py-0">
        <nav class="breadcrumb">
          <ul>
            <slot name="breadcrumb" />
          </ul>
        </nav>
        <o-navbar :title="title" :nav-items="navItems">
          <template #brand>
            <slot name="brand" />
          </template>
        </o-navbar>
      </div>
    </div>
    <section class="section">
      <slot />
    </section>
  </div>
</template>

<script>
import ONavbar from '@components/Navbar.vue'

export default {
  name: 'PageLayout',

  components: {
    ONavbar,
  },

  props: {
    title: {
      type: String,
      default: 'Untitled',
    },
    navItems: {
      type: Array,
      default: () => [],
      validator: (v) => v.every((obj) => ['to', 'label', 'active'].every((key) => key in obj)),
    },
  },

  metaInfo() {
    return {
      title: this.title,
    }
  },
}
</script>

<style lang="scss">
.page-layout {
  .navbar {
    z-index: 0;
  }
}
</style>
